.wrapper-project-study {
    background: #0e101f;
}

.ps-title {
    font-size: 4.5rem;
    font-weight: 700;
    color: rgb(175, 120, 81);
    text-shadow: 2px 2.3px rgb(78, 30, 40);
     /* use best font-family */
    font-family: 'Montserrat', sans-serif !important;
    text-align: center;
    padding-top: 30px;
    margin-bottom: 1rem;
    font-family: Roboto, BlinkMacSystemFont, system-ui, -apple-system, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.h2 {
    color: rgb(175, 120, 81);
    text-shadow: 2px 2.3px rgb(78, 30, 40);
    text-align: center;
    font-size: 2.7rem;

}

.description {
    color: rgb(189, 177, 140);
    margin: 0 33px;
    border-radius: 7px;
display: flex;
justify-content: center;
flex-direction: column;
gap: 60px;
    background: rgba(78, 70, 77, 0.69);
    font-size: 1.2rem;
    box-shadow: inset 0px 0px 0px 26px rgba(78, 70, 77, 0.69), 0px 10px 9px 6px rgba(24, 9, 49, 0.2);
    padding: 5rem;
    font-family: K2D, JetBrains Mono, Roboto, BlinkMacSystemFont, system-ui, -apple-system, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 28px;
}

.list-desc {
    background-color: rgba(78, 70, 77, 0.69) !important;
    color: rgb(189, 177, 140);
    text-align: justify;


}

.list-desc ol {
    margin: 0;
    list-style: none;
    padding: 0;
    counter-reset: my-counter;

}

.list-desc ol li {
    counter-increment: my-counter;
}

.list-desc ol li::before {
    content: counter(my-counter);
    background-color: rgba(78, 70, 77, 0.69);
    color: rgb(189, 177, 140);
    font-size: 1.7rem;
    line-height: 1;
    display: inline-flex;
    aspect-ratio: 1;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 0px 3px rgb(27, 6, 14);
    border-radius: 100%;
    width: 2rem;

    margin-right: 22px;
    margin-top: 33px;


}

.list-flex-box {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    background-color: rgba(78, 70, 77, 0.69);
    color: rgb(189, 177, 140);
    font-size: 1.2rem;
}

.list-flex-1 {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.list-flex-2 {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.wrap-list-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}

/* CAROUSEL */

.project-visuals {
    display: flex;
    gap: 40px;
    margin-bottom: 40px !important;
    position: relative;
    align-items: center;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 7px;
    background: rgba(78, 70, 77, 0.69);
    font-size: 1.2rem;
    width: 80%;
    padding: 1rem 1rem;
    font-family: K2D, JetBrains Mono, Roboto, BlinkMacSystemFont, system-ui, -apple-system, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 28px;
}
.img-container {
    width: 340px;
    height: auto;
    position: relative;
    
}
.img-container:nth-of-type(4) 
{
    width: 640px;
}
.carousel-item {
    width: 340px;
    height: auto;
    position: relative;
    box-shadow: 0px 4px 47px 3px rgba(30, 24, 39, 0.724);
    border-radius: 4px;
}


.wrap-carousel {

    display: flex;
    align-items: center;
    width: 2000;
    overflow: hidden;
    gap: 90px;
    padding-bottom: 5px;
    
}
.car-left-btn {
    position: absolute;
    width: 8%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #2c2532dc;
    opacity: 0.8;
    z-index: 20;
    cursor: pointer;

}

.car-right-btn {
    position: absolute;
    width: 8%;
    height: 100%;
    right: 0px;
    top: 0;
    background-color: #2c2532dc;
    opacity: 0.8;
    z-index: 20;
    cursor: pointer;

}

.car-left-btn:hover, .car-right-btn:hover {
    filter: brightness(1.3);
}
.car-left-btn:active, .car-right-btn:active {
    filter: brightness(1);
}
.fa-chevron-right,
.fa-chevron-left {
    font-size: 2rem;
    color: rgb(213, 145, 82);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 70;
}
.paditem {
    width: 500px;
    height: 300px;
    padding-left: 66px;
}
.img-container:hover .p-overlay {
    opacity: 0.9;
    transition: 0.15s ease;
    height: 100%;
    pointer-events: none;
  }


video {
    aspect-ratio: 0.5;
    width: inherit;
 
}
.vid-box {
    padding: 10px 0;
    box-shadow: none;
}
/* media queries */
@media only screen and (max-width: 700px) {
   .description {
    padding: 10px;
    box-shadow: none;
    margin: 0 5px;
    font-size: 0.9rem;
    text-align: left;
   }
   .h2 {
    font-size: 1.4rem;
   }
   .list-desc ol li::before {
    font-size: 1.2rem;
    width: 1.1rem;
   }
   .project-visuals {
    width: 98%;
}
.car-left-btn, .car-right-btn {
    height: 50px;
    top: 50%;
    border-radius: 4px;
}
.paditem {
    display: none;
}
}
@media only screen and (min-width: 1024px) {
    .description {
        margin: 0px 15%;
    }

    .h2 {
        text-align: center;
        padding: 60px 0px;
    }
   .wrap-list-box {
    justify-content: space-around;
    padding: 0;
   } 

.description:nth-of-type(2){
    padding: 0px;
    width: max-content;

}
}
@media only screen and (max-width: 1024px) {
    .fa-chevron-left, .fa-chevron-right {
        font-size: 0.9rem;
    }
}
@media only screen and (min-width: 640px) {
    video {
        aspect-ratio: 1.5;
   
    }
}
