* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

:root {
  --main-color: #4082e6;
}

html {
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: rgb(10, 6, 16);
  margin: 0;
  font-family: "Poppins", K2D, sans-serif;
  width: 100%;
}

/* Resume Page */
.resumepage {
  width: 27%;
}

/*Navigation*/

.navbar {
  display: flex;

  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 22px;
}
.sidemenu-item {
  color: rgb(228, 161, 18);
}
.navbar > a > img:hover {
  animation: logoanimation 0.9s ease-in-out infinite;
}
@keyframes logoanimation {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0px);
  }
}
.navlist {
  list-style-type: none;
  display: flex;
  gap: 60px;
  letter-spacing: -0.03em;
  background: -webkit-linear-gradient(rgb(189, 130, 54), rgb(205, 57, 183));
  -webkit-background-clip: text;
  background-clip: text;
  padding: 23px;
  -webkit-text-fill-color: transparent;
  font-family: "JetBrains Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: none;
}
.navlist > li:hover,
.contactnav > li:hover {
  cursor: pointer;
  color: #e99d52;
}

.contactnav {
  list-style-type: none;
  display: flex;
  gap: 56px;

  align-items: center;
  display: none;
}

nav .fa-brands {
  background: -webkit-linear-gradient(rgb(189, 130, 54), rgb(205, 57, 183));
  -webkit-background-clip: text;
  background-clip: text;
  padding: 23px;
  -webkit-text-fill-color: transparent;
  font-size: 1.2rem;
}
nav .cont-btn {
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 95px;
  height: 24px;
  border: none;
  color: #d09c19 !important;
  background: -webkit-linear-gradient(rgb(189, 130, 54), rgb(205, 57, 183));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
nav .cont-btn:hover {
  border: 3px solid rgb(74, 22, 74);
  border-image-source: linear-gradient(
    90deg,
    #d09c19,
    rgb(166, 103, 225)
  ) !important;
  cursor: pointer;
  border-image-slice: 10 !important;
}
/*Front Page*/
.intro-container {
  width: 100%;
  height: auto;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 203.45px;
  justify-content: center;
}

.intro-container > img {
  margin-top: 100px;
  margin-bottom: 100px;
}

.introtext-top {
  font-family: "JetBrains Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.05em;
  background: -webkit-linear-gradient(rgb(220, 172, 94), rgb(205, 119, 57));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.front-btn:hover {
  color: #3d0b61 !important;
  transform: scale(1.2) !important;
  border: 2px dotted #d09c19;
}
.name-highlight {
  background: -webkit-linear-gradient(rgb(189, 130, 54), rgb(205, 57, 183));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-style: bold;
}

.positiontitle {
  font-style: inherit;
  font-weight: 800;
  font-size: 29px;
  line-height: 44px;
  letter-spacing: -0.03em;
  background: -webkit-linear-gradient(rgb(189, 130, 54), rgb(205, 57, 183));
  -webkit-background-clip: text;
  background-clip: text;

  -webkit-text-fill-color: transparent;
}

.arrow-text {
  font-style: normal;
  font-weight: 500;
  color: #d09c19;
}

.primary-btn {
  width: 157.2px;
  height: 45.79px;

  font-family: "K2D";
  font-style: normal;
  font-weight: 800 !important;
  font-size: 26px;
  background: -webkit-linear-gradient(rgb(189, 130, 54), rgb(205, 57, 183));

  -webkit-background-clip: text;
  background-clip: text;

  -webkit-text-fill-color: transparent;
  line-height: 31px;
  border-radius: 3px;
  margin-top: 25px;
  border-image-source: linear-gradient(
    90deg,
    #d09c19,
    rgb(166, 103, 225)
  ) !important;
  cursor: pointer;
  border-image-slice: 10;
}
.primary-btn:hover {
  border: 2px solid rgb(234, 230, 222);

  border-image-source: linear-gradient(
    90deg,
    #9713e4,
    rgb(226, 194, 68)
  ) !important;
  cursor: pointer;
  border-image-slice: 10;
}

.mainimg {
  display: none;
}
/*About*/
.about-container {
  height: auto;

  margin-top: 53.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding-bottom: 100px;
}
.about-container::after {
  background-color: #e83f996b;
  width: 300px;
  height: 400px;
  position: absolute;
  z-index: 3;
}
.about-grid {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-bottom: 100px;
}

.heading {
  font-family: "JetBrains Mono";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 39px;
  text-align: center;
  letter-spacing: -0.03em;
  margin-top: 100px;
  margin-bottom: 140px;
  background: -webkit-linear-gradient(rgb(189, 130, 54), rgb(205, 57, 183));
  -webkit-background-clip: text;
  background-clip: text;
  padding: 23px;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
  text-decoration-color: #d09c19;
}
.heading span {
  color: #25072e;
}
.stack {
  margin: 0px;
}

.profile-img {
  width: 555px !important;
  height: auto;
  box-shadow: -6px 13px 9px 8px rgba(10, 6, 20, 0.251);
  border-radius: 15px;
}

.aboutme-main-text {
  width: 96%;
  height: auto;
  color: #ece6d7;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  display: flex;
  flex-direction: column;
}
.aboutme-main-text > p {
  padding: 0 10px;
  text-align: justify;
  background: -webkit-linear-gradient(rgb(189, 130, 54), rgb(205, 57, 183));
  -webkit-background-clip: text;
  background-clip: text;
  letter-spacing: 0.02em;
  -webkit-text-fill-color: transparent;
}

.styled-div-btn {
  font-size: 1rem;
  font-family: "JetBrains Mono";
  font-weight: 500;
  display: flex;

  align-items: center;
  justify-content: center;
  width: 157.2px;
  height: 45.79px;
  font-family: "K2D";
  font-style: normal;
  font-weight: 800;
  color: rgb(217, 170, 83) !important;
  background: -webkit-linear-gradient(rgb(211, 139, 44), rgb(205, 57, 166));

  -webkit-background-clip: text;
  background-clip: text;
  border: 1px solid #d09c19;
  -webkit-text-fill-color: transparent;
  line-height: 31px;
  border-radius: 3px;
  margin-top: 25px;
  border-image-source: linear-gradient(90deg, #d09c19, rgb(166, 103, 225));
  cursor: pointer;
  border-image-slice: 10;
}
.styled-div-btn:hover {
  border: 1px solid rgb(234, 230, 222);
  border-image-source: linear-gradient(
    90deg,
    #9713e4,
    rgb(226, 194, 68)
  ) !important;
  cursor: pointer;
  border-image-slice: 10;
}

.techstack-icon-container {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  background: -webkit-linear-gradient(rgb(189, 130, 54), rgb(205, 57, 183));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fa-brands {
  display: block;

  font-size: 22px;
}

.wrapicon {
  width: 100px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  height: 120px;

  align-items: center;
  margin-top: 125px;
}

/*Project page*/
.project-container {
  margin-bottom: 5px;
  color: #25072e;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 8px #03020e6b;
  background: linear-gradient(90deg, rgb(217, 76, 146), #d08119);
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 10px;
  position: relative;
  padding-bottom: 10px;
}
.view-live-container {
  display: flex;
  width: 100%;
  height: max-content;
  align-items: center;
  justify-content: space-around;
}
.proj-btns {
  width: max-content !important;
  margin: 0 !important;
  background-color: rgb(217, 145, 83);
  padding: 0.4rem 0.9rem;
  border-radius: 5px;
  font-weight: bold;
}
.proj-btns:hover {
  filter: brightness(0.67);
}
.code {
  color: rgb(217, 145, 83);
}
.code:hover {
  filter: brightness(0.67);
}
/* #Projects-Page {
  display: flex;
justify-content: center;
flex-direction: column;
}
.feature{
  margin: 0px auto;
  width: max-content;
}
.feature img {
  max-width: 32em;
  height: auto;
  border-radius: 5px 5px 0 0;
  box-shadow: 0px 0px 5px 8px #03020e6b;
} */
.p-overlay {
  background: #171b20;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0;
}
.project-container:hover .p-overlay {
  opacity: 0.96;
  transition: 0.3s ease;
}
.p-overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
  color: #cf9556;
  font-size: 1.3rem;
  font-weight: 600;
  text-align: left;
  z-index: 1000;
}
.wrap-project {
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(16rem, 26rem));
  justify-content: center;
  gap: 20px;
}

.project-title {
  font-size: 1.2rem;
  font-weight: 400;
}

.projects {
  margin-top: 270px;
  background: -webkit-linear-gradient(rgb(189, 130, 54), rgb(205, 57, 183));
  -webkit-background-clip: text;
  background-clip: text;
  padding: 23px;
  -webkit-text-fill-color: transparent;
}

.project-img {
  width: 100%;
  height: auto;
  border-radius: 5px 5px 0px 0px;
}
.project-video {
  width: 100%;
  height: auto;
  border-radius: 5px 5px 0px 0px;
}
/* CONTACT */
.contactform-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
/* mark */
.form-border {
  width: max-content;

  font-family: "K2D";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 178%;

  margin: auto;
  margin-top: 60px;
  display: flex;
  padding: 25px;
  flex-direction: column;
  background: #110f0fa9;
}

input,
textarea {
  display: block;
  background: #161517;
  border-radius: 2px;
  border: none;
  padding: 7px 5px;
  margin-bottom: 25px;
  max-width: inherit;
}
input::placeholder,
textarea::placeholder {
  color: rgb(193, 25, 188);
}
input {
  height: 30px;

  outline: none;
  padding-inline-start: 5px;
  padding-top: 5px;
  color: rgb(166, 67, 164);
  font-size: 1.4rem;
  font-family: "Poppins";
}

textarea {
  resize: none;

  outline: none;
  padding-inline-start: 5px;
  padding-top: 5px;
  color: rgb(166, 67, 164);
  font-size: 1.4rem;
  font-family: "Poppins";
}

label {
  color: white;
}
.email-msg {
  font-size: 26px;
  display: inline-block;
}
.sendform {
  margin: auto;
  width: 100%;
  height: 35px;
  font-family: "K2D";
  font-style: bold;
  font-weight: 700;
  font-size: 28px;
  line-height: 178%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  text-align: center;
  background-color: #08010d4a;
  color: rgb(222, 220, 212);
  border: 2px solid rgb(234, 230, 222);
  color: rgb(198, 178, 105);
  border-image-source: linear-gradient(
    90deg,
    #9713e4,
    rgb(226, 194, 68)
  ) !important;
  cursor: pointer;
  border-image-slice: 10;
  background: -webkit-linear-gradient(rgb(189, 130, 54), rgb(203, 65, 182));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.sendform:hover {
  border: 2px solid rgb(234, 230, 222);
  border-image-source: linear-gradient(
    90deg,
    #d09c19,
    rgb(166, 103, 225)
  ) !important;
  cursor: pointer;
  border-image-slice: 10;
}
.contact-heading {
  letter-spacing: -0.205rem;
  padding: 25px;
  margin-top: 205px;
  background: -webkit-linear-gradient(rgb(189, 130, 54), rgb(205, 57, 183));
  -webkit-background-clip: text;
  background-clip: text;
}

.contactform-icons,
.contactform-icons > i {
  font-size: 3.9rem;
  display: flex;
  color: #743f9a;
  gap: 60px;
  opacity: 0.9;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
  margin-top: 90px;
}

footer {
  text-align: center;
  color: #cc953c;
  opacity: 0.8;
  font-size: 10px;
  background: rgba(27, 5, 60, 0.847);
  padding-top: 10px;
  margin-top: 20px;
}

.footer-logo {
  color: #cc953c;
}

/*MEDIA QUERIES*/
@media only screen and (max-width: 400px) {
  .positiontitle {
    font-size: 1.282rem;
    text-align: center;
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 640px) {
  .profile-img {
    width: 100% !important;
  }
}
.primary-btn {
  width: 300px;
}
.hire-me {
  transform: scale(0.8);
}
.introtext-top,
.introtext-top span {
  font-size: 0.56rem;
  text-align: center;
}

/* SIDE MENU STYLING */
@media only screen and (max-width: 1023px) {
  .sidemenubox {
    background: #190a1e;
    width: 100%;
    height: 100vh;
    color: #f7ce68;
    font-size: 1.5rem;
    overflow-x: hidden;
    text-align: center;
    padding: 40px;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation: animatehamMenu 0.24s ease-in;
    -moz-animation: animatehamMenu 0.24s ease-in;
    animation: animatehamMenu 0.24s ease-in;
  }
  .contactform-container > p {
    font-size: 0.9rem;
    display: block;
    margin: auto;
  }
  .menu {
    position: relative;
    top: 16.4px;
    left: 18px;
    z-index: 10;
    font-size: 1.25rem;
    color: rgb(118, 25, 101);
  }

  .menu span {
    color: #b18820;
    font-size: 1.38rem;
    font-weight: 600;
  }
  @keyframes animatehamMenu {
    0% {
      opacity: 0;
      width: 0%;
      color: #cf6444;
    }
    100% {
      opacity: 1;
      width: 100%;
    }
  }
  .sidemenubox > ul {
    list-style-type: none;
    line-height: 70px;
  }

  .sidemenubox ul li i {
    display: inline-block;
  }
  .positiontitle {
    font-size: 1.7rem;
  }
  .form-border {
    width: 90%;
    margin-top: 0;
    padding: 3;
    margin: auto;
  }
}
input,
textarea {
  width: 100%;
}
.fa-circle-xmark {
  position: absolute;
  top: 23px;
  right: 14px;
  cursor: pointer;
}

@media screen and (max-width: 320px) {
  * {
    font-size: 0.95rem;
  }

  .about-container {
    width: 102%;
  }

  .wrap-project {
    width: 102%;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1094px) {
  .about-container {
    display: grid;
    grid-template-columns: minmax(1fr, 1fr) minmax(1fr, 1fr);
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .positiontitle {
    margin-bottom: 60px;
    margin-top: 30px;
  }
}
@media only screen and (min-width: 769px) {
  .positiontitle {
    font-size: 3rem;
  }

  /* animate main image svg */
  .mainimg {
    animation: animatesvg 5s ease-in-out infinite;
  }
  @keyframes animatesvg {
    0% {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(-30px);
      opacity: 0.4;
    }

    100% {
      transform: translateY(0px);
    }
  }

  .scroll {
    display: none;
  }
  .aboutme-main-text {
    margin-top: 50px;
    width: 70%;
  }

  .wrap-project {
    gap: 100px;
  }
  .techstack-icon-container {
    gap: 55px;
  }
  .introtext-top,
  .introtext-top span {
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 960px) {
  .fa-bars {
    display: none;
  }
  .navlist {
    display: flex;
  }
  .contactnav {
    display: flex;
  }
}
@media only screen and (min-width: 760px) and (max-width: 1023px) {
  .primary-btn,
  .hire-me {
    width: 240px;
    font-size: 3.7rem;
  }
}
@media only screen and (min-width: 321px) and (max-width: 1023px) {
  .primary-btn,
  .hire-me {
    width: 200px;
    font-size: 0.9rem;
  }
}
.introtext-top {
  font-size: 1.2rem;
}
.introtext-top > span {
  font-size: 1.5rem;
}
@media only screen and (min-width: 1025px) {
  /* General application */
  .heading {
    font-size: 4.3rem;
  }
  .form-border {
    width: 60%;
  }
  .contactform-container > p {
    margin: auto;
    font-size: 3.5rem;
  }
  /* navigation */
  .navbar {
    padding-left: 70px;
    padding-right: 70px;
  }

  /* FRONT PAGE RE STYLING */
  .intro-container {
    align-items: start;
    padding-left: 70px;
    padding-bottom: 200px;
    margin-top: 300px;
  }
  .positiontitle {
    font-style: normal;
    font-weight: 500;
    font-size: 68px;
    line-height: 96px;
    letter-spacing: -0.03em;
    margin-bottom: 180px;
  }
  .introtext-top,
  .introtext-top span {
    font-size: 1.3rem;
  }
  .info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .mainimg {
    display: inline-block;
    position: absolute;
    z-index: -1;
    right: 0;
    opacity: 0.7;
  }
  /* mouse icon */
  .mouse {
    width: 25px;
    height: 40px;
    border: 3px solid rgb(120, 37, 115);
    border-radius: 30px;
    position: absolute;
    left: 50vw;
    top: 94vh;
    opacity: 0.9;
  }
  .mouse::before {
    content: "";
    width: 7px;
    height: 7px;
    position: absolute;
    top: 0px;
    left: 45%;
    transform: translateX(-50%);
    background-color: #ce881e;
    border-radius: 50%;
    opacity: 0.9;
    -webkit-animation: wheel 2.3s infinite;
    -moz-animation: wheel 2.3s infinite;
    animation: wheel 2.3s infinite;
  }

  @keyframes wheel {
    to {
      opacity: 0;
      top: 26px;
    }
  }

  @-webkit-keyframes wheel {
    to {
      opacity: 0;
      top: 37px;
    }
  }

  /* About Restyling */
  .profile-img {
    width: 280px;
  }
  .about-container {
    gap: 100px;
  }
  .techstack-icon-container {
    gap: 90px;
  }
  .icon-text {
    font-size: 1.1rem;
  }
  .about-container .fa-brands {
    font-size: 4rem;
  }
}
.contactform-container > p {
  font-size: 1rem;
  display: block;
  color: #b22eb2;
}
/* create custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #1e2b36;
}
::-webkit-scrollbar-thumb {
  background: #9a612f;
  border-radius: 3px;
}
.experience-section {
  width: 90%;
  margin: auto;
}
.experience-article {
  color: rgb(193, 25, 188) !important;
  text-align: justify;
}
.experience-article ul {
  list-style-type: circle !important;
}
